<template>
  <div id="calendar">
		<v-snackbar
			v-model="show_snackbar"
			:timeout="2000"
			:color="snackbar_color"
			top
			centered
			transition="slide-y-transition"
		>
			<v-icon class="mr-1">{{ snackbar_icon }}</v-icon><span class="white--text">{{ snackbar_text }}</span>
		</v-snackbar>

    <!-- Time selection bar -->
    <v-system-bar
      v-if="select_blocked_time || change_app_mode"
      height="65"
      fixed
      style="z-index: 999999999;"
      color="#037aff"
      dark
    >
      <div class="d-flex justify-center text-h6 white--text" style="width: 100%;">Select a time to block</div>
      <v-btn icon large>
        <v-icon color="white" large @click="select_blocked_time = false;change_app_mode = false;">mdi-close</v-icon>
      </v-btn>
    </v-system-bar>
    <!-- End selection bar -->

    <!-- Top Filters -->
    <v-row>
      <!-- Staff dropdown -->
      <v-col cols="2">
        <v-select
          :items="allCategories"
          item-value="id"
          item-text="employee_name"
          v-model="defaultCategory"
          dense
          outlined
          @change="changeCategory()"
          background-color="white"
          class="custom-select"
          style="width:95%"
        >
        <!-- class="custom-select" -->
        </v-select>
      </v-col>
      <!-- End staff dropdown -->

      <!-- Branch dropdown -->
      <v-col cols="2">
        <v-select
          :items="allBranches"
          item-value="id"
          item-text="id"
          v-model="selected_branch"
          dense
          outlined
          @change="changeBranch()"
          background-color="white"
          class="custom-select"
          style="width:95%"
        >
        </v-select>
      </v-col>
      <!-- End branch dropdown -->

      <!-- Date block -->
      <v-col cols="5" style="text-align: center">
        <!-- v-model="toggle_exclusive" -->
        <v-btn-toggle class="date_button">
          <v-btn color="white" @click="prev" active-class="remove-opacity">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn background-color="white" :class="focus == today ? 'v-btn--active' : ''" text @click="setToday"> Today </v-btn>
          <v-btn background-color="white" width="215px" text v-if="$refs.calendar" active-class="remove-opacity">
            <!-- {{ $refs.calendar.title }} -->
            {{ top_filter_date }}
          </v-btn>
          <v-btn color="white" @click="next" active-class="remove-opacity">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <!-- End Date block -->

      <!-- Last block -->
      <v-col cols="3">
        <div class="last_block">
          <!-- <v-btn class="setting_btn" color="white">
            <v-icon> mdi-cog-outline</v-icon>
          </v-btn> -->

          <v-select
            :items="calendarType"
            item-text="label"
            item-value="value"
            v-model="selectedCalendarType"
            dense
            outlined
            background-color="white"
            v-on:change="changeCalendarType"
            class="custom-select days-select"
            style="width: 95% !important;margin-left: 0px !important;"
          >
          </v-select>

          <v-menu
            transition="slide-y-transition"
            bottom
            content-class="my-menu"
          >
            <template v-slot:activator="{ on }">
              <v-btn color="#1a263a" dark v-on="on" class="add-new">
                Add New
              </v-btn>
            </template>

            <v-list>
							<v-list-item link @click="click_new_appointment()">
								<v-list-item-title>New Appointment</v-list-item-title>
							</v-list-item>
							<v-list-item link @click="chooseBlockedTime">
								<v-list-item-title>New Blocked Time</v-list-item-title>
							</v-list-item>
							<!-- <v-list-item link>
								<v-list-item-title>New Sale</v-list-item-title>
							</v-list-item> -->
						</v-list>
          </v-menu>
        </div>
      </v-col>
      <!-- End Last block -->
    </v-row>
    <!-- <v-row>
      <v-col cols="1">
        <div class="arrow-calendar-left">
              <v-btn color="#101928" dark class="next-employee" @click="previous_employee_load()">
                <i class='fas fa-less-than'></i>
              </v-btn>
        </div>
      </v-col>
      <v-col cols="10"></v-col>
      <v-col cols="1">
        <div class="arrow-calendar-right"> -->
              <!-- <v-btn color="#101928" dark class="next-employee" @click="previous_employee_load()">
                <i class='fas fa-less-than'></i>
              </v-btn> -->
              <!-- <v-btn color="#101928" dark class="next-employee" @click="next_employee_load()">
                  <i class='fas fa-greater-than'></i>
              </v-btn>
        </div>
      </v-col>

    </v-row> -->
    <!-- End Top Filters -->

    <div class="show-calendar">
      <!-- Calender staff tab -->
      <!-- v-model="tabs" -->
      <!-- <v-tabs
				grow
				icons-and-text
				background-color="transparent"
			>
				<v-tab
					href="#mobile-tabs-5-1"
					v-for="(item, i) in staffList"
					:key="i"
					@mouseover="c_index=i" @mouseleave="c_index=-1"
				>
					<span class="staff_name">{{ item.label }}</span>
					<v-avatar color="#a5dff8" size="44">
						<span class="calender_avatar">{{ item.avatar }}</span>
					</v-avatar>

					<v-menu transition="slide-y-transition" right>
						<template v-slot:activator="{ on }">
							<v-icon v-show="c_index==i" right v-on="on" color="black" class="staff_schedule">
								mdi-chevron-down
							</v-icon>
						</template>

						<v-list class="schedule_dd">
							<template 
								v-for="(item, i) in scheduleList"
							>
								<v-subheader v-if="item.value == null" :key="i">{{ item.label }}</v-subheader>
								<v-list-item
									v-if="item.value != null"
									:key="i"
									@click="addItem(item)"
								>
									{{ item.label }}
								</v-list-item>
							</template>
						</v-list>
					</v-menu>
				</v-tab>
			</v-tabs> -->
      <!-- End Calender staff tab -->
      <!-- Calender -->
      <template>
        <v-row class="fill-height">
          <v-col class="custom-calender">
            <v-sheet height="510">
              <v-calendar
                ref="calendar"
                v-model="focus"
                color="primary"
                :type="selectedCalendarType"
                :categories="currentCategories"
                category-text="employee_name"
                :events="events"
                :event-color="getEventColor"
                :event-ripple="false"
                event-text-color="black"
                @change="fetchEvents"
                @click:event="ViewAppoitment"
                :show-interval-label="showIntervalLabel"
                :interval-minutes="15"
                :interval-count="96"
                :interval-height="25"
                @mousedown:event="startDrag"
                @mousedown:time="startTime"
                @mousemove:time="mouseMove"
                @mouseup:time="endDrag"
                @mouseleave.native="cancelDrag"
                @mouseenter:event="openPopover"
                @mouseleave:event="closePopover"
                :interval-format="intervalFormat"
                category-show-all
              >
              <!-- category-hide-dynamic -->
              <!-- #category-show-all -->
                <template v-slot:event="{ event, timed }">
                  <!-- eventSummary -->
                  <!-- v-html="eventSummary()" -->
                  <div v-if="change_app_mode" class="select_time_overlay" style="width: 100%;height: 100%;background: rgb(255 255 255 / 50%);position: absolute;"></div>
                  <div class="v-event-draggable" v-if="event.type == 1" >

                    <v-tooltip v-if="event.sales_invoice && event.paid == 1" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="pa-1" style="float:right" size="14px" color="#101928">mdi-file</v-icon>
                      </template>
                      <span>Fully paid</span>
                    </v-tooltip>

                    <v-tooltip top v-if="event.sales_invoice && event.paid == 0">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="pa-1" style="float:right" size="14px" color="#101928">mdi-file-outline</v-icon>
                      </template>
                      <span>Unpaid Invoice</span>
                    </v-tooltip>

                    <span>{{ event.start | unix_time_format }}</span> - <span>{{ event.end | unix_time_format }}</span>
                    <br>
                    <span class="text-subtitle-2 font-weight-medium">{{ event.name }}</span>
                    <p v-if="event.name != 'Default Customer'" class="text-sm mb-0">{{ event.customer_group }}</p>
                    <p>{{ event.service }}</p>
                  </div>
                  <div class="v-event-draggable" v-if="event.type == 2" >
                    <span>{{ event.start | unix_time_format }}</span> - <span>{{ event.end | unix_time_format }}</span>
                     <span class="ml-2 text-subtitle-2 font-weight-medium">Blocked Time</span>
                  </div>
                  <div
                    v-if="timed && event.type !== 2"
                    class="v-event-drag-bottom"
                    @mousedown.stop="extendBottom(event)"
                  >
                  </div>
                </template>

                <template class="check" v-slot:interval="{ time, date, category }">
                  <div
                    class="text-center interval-slot-time"
                    :class="select_blocked_time ? 'block-time-slot' : ''"
                    v-on:click="createNewEvent(date, time, category)"
                    v-if="checkBlockTime(date, time, category)"
                  >
                    <span v-if="select_blocked_time">{{ blocked_time_format(time) }}</span>
                    <span v-else>{{ time | time_format }}</span>
                  </div>
                  <div v-else class="text-center interval-slot-time blocked">
                    Blocked time
                  </div>
                </template>

                <template v-slot:category="{ category }">
                  <div
                    class="text-center custom-category-label pa-2"
                  >
                    <div>
                      <v-avatar color="#a5dff8" size="44">
                        <span class="calender_avatar">{{ getCategoryLabel(category).charAt(0) }}</span>
                      </v-avatar>
                    </div>
                    <span class="text-subtitle-2">{{ getCategoryLabel(category) }}</span>
                  </div>
                </template>

                <template v-slot:day-body="{ date, week }">
                  <div
                    v-if="date == new Date().toISOString().substr(0, 10)"
                    class="v-current-time"
                    :class="{ first: date === week[0].date }"
                    :style="{ top: nowY }"
                  ></div>
                </template>
              </v-calendar>

              <!-- Event mouseover popup -->
              <v-menu
                v-model="selectedOpen"
                :close-on-content-click="false"
                :activator="selectedElement"
                style="z-index: 999999999"
                rounded="0"
                offset-y
                offset-overflow
              >
                <v-card v-if="popup_type == 1">
                  <div class="pa-2">
                    <v-avatar
                      color="grey lighten-2"
                      size="60"
                      class="mr-3 mb-2"
                    >
                      <span style="width: 32px;height: 32px;">
                        <svg viewBox="0 0 20 22" xmlns="http://www.w3.org/2000/svg"><g fill="#FFF" stroke="#101928"><path d="M.5 21c0-5.5313 4.1043-9.5 9.5-9.5s9.5 3.9687 9.5 9.5v.5H.5V21z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15.5 6c0-3.0374-2.4626-5.5-5.5-5.5S4.5 2.9626 4.5 6s2.4626 5.5 5.5 5.5 5.5-2.4626 5.5-5.5z"></path></g></svg>
                      </span>
                    </v-avatar>
                    <div style="display:inline-block">
                      <span class="text-subtitle-1 font-weight-medium" style="display:block;line-height: 18px !important;">
                        {{ selectedEvent.name }}
                      </span>
                      <span v-if="selectedEvent.name != 'Default Customer'" style="display:block" class="text-subtitle-2 font-weight-regular mb-0">{{ selectedEvent.customer_group }}</span>
                    </div>
                    

                    <v-divider></v-divider>

                    <div class="pa-2">
                      <div>
                        <span class="text--secondary text-body-2">{{ selectedEvent.start | unix_time_format_12 }} - {{ selectedEvent.end | unix_time_format_12 }}</span> 
                        <span v-if="selectedEvent.sales_invoice" class="text-caption text-uppercase ml-2 grey--text text--darken-1 font-weight-medium">Completed</span>
                        <span v-else class="text-caption text-uppercase ml-2 blue--text text--darken-3 font-weight-medium">{{ selectedEvent.event_status }}</span>
                      </div>
                      <v-row class="font-weight-medium">
                        <v-col cols="6">{{ selectedEvent.service }}</v-col>
                        <v-col cols="6" class="text-right">{{ $currency }}{{ selectedEvent.rate }}</v-col>
                      </v-row>
                      <div class="text--secondary text-body-2 mt-1">{{ selectedEvent.duration }}min with {{ selectedEvent.category }}</div>
                    </div>
                  </div>

                </v-card>

                <v-card v-if="popup_type == 2">
                  <div class="pa-2">
                    <div class="pa-2">
                      <div>
                        <span class="text-body-2">{{ selectedEvent.start | unix_time_format_12 }} - {{ selectedEvent.end | unix_time_format_12 }}</span> 
                      </div>
                    </div>
                  </div>
                </v-card>
            
              </v-menu>
              <!-- End event mouseover popup -->

              <!-- hide-header -->
            </v-sheet>
          </v-col>
        </v-row>
      </template>
      <!-- End Calender -->
    </div>
    
		<!-- New blocked time dialog -->
		<v-dialog
			v-model="block_time_dialog"
			persistent
			max-width="450px"
			transition="dialog-bottom-transition"
			scrollable
		>
			<v-card class="block_dialog">
				<v-card-title class="p-0 text-center">
					<v-app-bar color="white" flat>
						<v-app-bar-title class="text-h5 font-weight-bold">{{ blocked_dialog_title }}</v-app-bar-title>
						<v-btn
							icon
							@click="block_time_dialog = false; update_block_time_id = null"
							class="close_dlog"
						>
							<v-icon large>mdi-close</v-icon>
						</v-btn>
					</v-app-bar>
					
				</v-card-title>
				<v-divider></v-divider>

				<v-card-text>
					<v-container class="px-0 pb-0 pt-2">
						<v-row>
							<v-col
								cols="12"
								sm="12"
								md="12"
								class="pt-8 pb-0"
							>
								<v-row>
									<v-col
										cols="6"
										class="pt-0 pb-2"
									>
										<v-checkbox
											v-model="dialog_all_staff"
											label="Block for all staff"
											color="primary"
											class="mt-0 pt-0"
										></v-checkbox>
									</v-col>
									<v-col
										cols="6"
										class="pt-0 pb-0"
									>
										<v-checkbox
											v-model="dialog_all_date"
											label="Block for all day"
											color="primary"
											class="mt-0 pt-0"
										></v-checkbox>
									</v-col>
                  <!-- <v-col
										cols="4"
										sm="4"
										md="4"
										class="pt-0 pb-0"
									>
										<v-checkbox
											v-model="dialog_all_branch"
											label="Block For All Branch"
											color="primary"
											class="mt-0 pt-0"
										></v-checkbox>
									</v-col> -->
								</v-row>
							</v-col>
							<v-col
							cols="12"
							sm="12"
							md="12"
							class="pt-0 pb-0"
							v-if="!dialog_all_date"
							>
								<label class="dialog_label">Date</label>
								<v-menu
									ref="menu"
									v-model="menu"
									:close-on-content-click="false"
									transition="scale-transition"
									offset-y
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											v-model="formatted_blocked_date"
											readonly
											v-bind="attrs"
											v-on="on"
											solo
											append-icon="mdi-menu-down"
										></v-text-field>
									</template>
									<v-date-picker
										v-model="blocked_date"
										no-title
										scrollable
										@change="save"
									></v-date-picker>
								</v-menu>
							</v-col>
							<v-col
								cols="12"
								sm="12"
								md="12"
								class="pt-0 pb-0"
								v-if="!dialog_all_staff"
							>
								<label class="dialog_label">Staff</label>
								<v-select
									:items="categories"
									item-text="employee_name"
									item-value="id"
									v-model="dialog_staff"
									solo
								></v-select>
							</v-col>
<!-- branch selection -->
							<v-col
								cols="12"
								sm="12"
								md="12"
								class="pt-0 pb-0"
							>
								<label class="dialog_label">Branch</label>
								<v-select
									:items="allBranches"
									item-text="id"
									item-value="id"
									v-model="dialog_branch"
									solo
								></v-select>
							</v-col>

							<v-col
								cols="12"
								sm="12"
								md="12"
								class="pt-0 pb-0"
							>
								<v-row>
									<v-col
										cols="6"
										sm="6"
										md="6"
									>
										<label class="dialog_label">Start time</label>
										<v-select
											:items="getTimes"
                      item-text="label"
                      item-value="value"
											v-model="dialog_start_time"
											solo
										></v-select>
									</v-col>

									<v-col
										cols="6"
										sm="6"
										md="6"
									>
										<label class="dialog_label">End time</label>
										<v-select
											:items="getTimes"
                      item-text="label"
                      item-value="value"
											v-model="dialog_end_time"
											solo
											:error="dialog_error"
											:rules="checkGreaterThanStartTime"
										></v-select>
									</v-col>
								</v-row>
							</v-col>

							<v-col
								cols="12"
								sm="12"
								md="12"
								class="pt-0 pb-0"
							>
								<v-checkbox
									v-model="dialog_online_booking"
									label="Allow online bookings during blocked time"
									color="primary"
									class="mt-0 pt-0"
								></v-checkbox>
							</v-col>

							<v-col
								cols="12"
								sm="12"
								md="12"
								class="pt-0 pb-0"
							>
								<label class="dialog_label">Description</label>
								<v-textarea
									solo
									v-model="dialog_description"
									placeholder="e.g. lunch meeting"
									auto-grow
									rows="4"
									row-height="20"
								></v-textarea>
							</v-col>

						</v-row>
					</v-container>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions v-if="blocked_dialog_title == 'New Blocked Time'">
					<v-btn
						block
						large
						color="#101928"
						dark
						@click="save_blocked_time()"
					>
						Save
					</v-btn>
				</v-card-actions>
        <v-card-actions v-else>
					<v-btn
						large
						color="#da2346"
						dark
            width="50%"
            @click="delete_blocked_time()"
					>
						Delete
					</v-btn>
          <v-btn
						large
						color="#101928"
						dark
						@click="save_blocked_time()"
            width="50%"
					>
						Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- End new blocked time dialog -->
  </div>
</template>

<script>
import moment from 'moment'
import getTimes from '../helpers/get_times.js';
import CommonApi from '@/services/CommonApi'

export default {
  name: "Calendar",
  components: {},
  data() {
    return {
      block_time_dialog: false,
			blocked_date: new Date().toISOString().substr(0, 10),
			menu: false,
			dialog_staff: 1,
      getTimes : getTimes(),
			dialog_start_time: '0:00',
			dialog_end_time: '0:30',
			staffs: [],
			checkGreaterThanStartTime: [ true ],
			dialog_error: false,
			dialog_all_staff: false,
			dialog_all_date: false,
			dialog_all_branch: false,
      dialog_branch: 'All Branch',
      // dialog_all_branch:false,
			dialog_online_booking: false,
			dialog_description: '',
			show_snackbar: false,
			snackbar_text: '',
			snackbar_color: '',
			snackbar_icon: '',
      selectedCalendarType: "category",
      c_index: -1,
      selectedStaff: 1,
      offset: true,
      focus: new Date().toISOString().substr(0, 10),
      events: [],
      colors: ['#a5dff8', '#a5dff8', '#a5dff8'],
      // ["#A5DFF8"],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      custom_events: [
        {
          start: "[Sun May 23 2021 14:15:00 GMT+0530 (India Standard Time)]",
          end: "[Sun May 23 2021 16:00:00 GMT+0530 (India Standard Time)]",
          name: "John Doe",
          color: "#A5DFF8",
          timed: true,
          category: "Dhaval New",
        },
        {
          start: "[Tue May 25 2021 19:45:00 GMT+0530 (India Standard Time)]",
          end: "[Tue May 25 2021 21:15:00 GMT+0530 (India Standard Time)]",
          name: "John Doe",
          color: "#A5DFF8",
          timed: true,
          category: "Dhaval New",
        },
        {
          start: "[Mon May 24 2021 10:15:00 GMT+0530 (India Standard Time)]",
          end: "[Mon May 24 2021 11:00:00 GMT+0530 (India Standard Time)]",
          name: "John Doe",
          color: "#A5DFF8",
          timed: true,
          category: "Bhavesh Test",
        },
        {
          start: "[Wed May 26 2021 06:45:00 GMT+0530 (India Standard Time)]",
          end: "[Wed May 26 2021 07:45:00 GMT+0530 (India Standard Time)]",
          name: "John Doe",
          color: "#A5DFF8",
          timed: true,
          category: "Bhavesh Test",
        },
        {
          start: "[Wed May 26 2021 05:15:00 GMT+0530 (India Standard Time)]",
          end: "[Wed May 26 2021 06:00:00 GMT+0530 (India Standard Time)]",
          name: "John Doe",
          color: "#A5DFF8",
          timed: true,
          category: "WendySmith",
        },
        {
          start: "[Mon May 24 2021 01:30:00 GMT+0530 (India Standard Time)]",
          end: "[Mon May 24 2021 16:15:00 GMT+0530 (India Standard Time)]",
          name: "John Doe",
          color: "#A5DFF8",
          timed: false,
          category: "WendySmith",
        },
      ],
      calendarType: [
        {
          label: "Day",
          value: "category",
        },
        {
          label: "4 Days",
          value: "4day",
        },
        {
          label: "Week",
          value: "week",
        },
      ],
      selectedCategoryOfCalendar: 0,
      categories: [],
      currentCategories: [],
      staticcurrentCategories: ["Dhaval New", "Bhavesh Test", "WendySmith"],
      allCategories: [
        { "id": "all", "employee_name": "All Employees" }
      ],
      defaultCategory: "all",
      scheduleList: [
        {
          label: "Quick jump",
          value: null,
        },
        {
          label: "Day view - Dhaval",
          value: 1,
        },
        {
          label: "3 days view - Dhaval",
          value: 2,
        },
        {
          label: "Week view - Dhaval",
          value: 3,
        },
        {
          label: "Dhaval's schedule",
          value: null,
        },
        {
          label: "Add blocked time",
          value: 4,
        },
        {
          label: "Edit working hours",
          value: 5,
        },
      ],
      selected: 1,
      all_appointment: [],
      all_blocked_time: [],
      blocked_data: [{date : "2021-06-13", time : '02:00', category: { categoryName: "Bhavesh1", employee_name: "Nilesh", id: "HR-EMP-00003" }}],
      ready: false,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      popup_type : 1,
      top_filter_date: moment().format('dddd, D MMMM Y'),
      delay: 700,
      clicks: 0,
      timer: null,
      extended_event:null,
      staff_wise_event: [],
      staff_wise_blocked_time: [],
      today : new Date().toISOString().substr(0, 10),
      select_blocked_time : false,
      blocked_dialog_title : 'New Blocked Time',
      update_block_time_id : null,
      change_app_mode : false,
      allBranches: [
        { "id": "All Branch" }
      ],
      selected_branch: '',
      start:0,
      pagination: 0
    };
  },
  filters: {
    time_format: function (time) {
      return moment(time, ["HH:mm"]).format('h:mma');
    },
    unix_time_format : function (time) {
      return moment.unix(time/1000).format('hh:mm');
    },
    unix_time_format_12 : function (time) {
      return moment.unix(time/1000).format('h:mma');
    },
    // time_format_24: function (date) {
    //   return moment(date, ["YYYY-MM-DD HH:mm"]).format('hh:mm');
    // }
  },
  computed: {
    cal () {
      return this.ready ? this.$refs.calendar : null
    },
    nowY () {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
    },
    formatted_blocked_date() {
      return this.blocked_date ? moment(this.blocked_date).format('dddd, D MMMM Y') : "";
    },
    // top_filter_date() {
    //   return this.$refs.calendar.days[0].date ? moment(this.$refs.calendar.days[0].date).format('dddd, D MMMM Y') : "";
    // }
  },
  mounted() {
    this.ready = true
    this.$refs.calendar.checkChange();
    
    this.reschedule_appointment();


  },
  updated() {
    if (this.selectedCalendarType == "category") {
      document.querySelector('.v-calendar-daily_head-day-label').style.display = 'none';
      document.querySelector('.v-calendar-daily_head-weekday').style.display = 'none';
    }
  },
  methods: {
    reschedule_appointment() {
      if(this.$route.query.type == 'reschedule') {
        this.focus = this.$route.query.date;
        this.top_filter_date = moment(this.focus).format('dddd, D MMMM Y');
        this.change_app_mode = true;
      }
    },
    next_employee_load() {
      this.fetchEvents();
      this.pagination += 1
      if(this.pagination < 0) {
        this.pagination = 0
      }
    },
    previous_employee_load() {
      if(this.pagination >= 1){
        this.start = (this.pagination - 1) * 8
      }else{
        this.start = 0
      }
      // if (this.pagination <= 1){
      //     this.start = (this.pagination - 1) * 8
      // }else{
      //   this.start = 8
      // }
      
      this.fetchEvents();
      this.pagination -= 1
      if(this.pagination < 0) {
        this.pagination = 0
      }
    },
    click_new_appointment() {
      if(this.selected_branch == 'All Branch') {
        this.show_snackbar = true;
        this.snackbar_text = 'Please select the branch.';
        this.snackbar_color = 'error';
        this.snackbar_icon = 'mdi-alert-outline';
      } else {
        this.$router.push('/appointment');
      }
    },
		save_blocked_time() {
			let start = moment(this.dialog_start_time,'HHmmss').format("HH:mm:ss");
			let end = moment(this.dialog_end_time,'HHmmss').format("HH:mm:ss");
			if(start >= end) {
				this.dialog_error = true;
				this.checkGreaterThanStartTime = [ "End time must be later than start time" ];
			} else {
				this.dialog_error = false;
				this.checkGreaterThanStartTime = [ true ];
			}

			if(!this.dialog_error) {
				const post_data = new FormData();
				post_data.append("from_time", start)
				post_data.append("to_time", end)
				if(!this.dialog_all_staff) {
					post_data.append("staff", this.dialog_staff)
				}
				if(!this.dialog_all_date) {
					post_data.append("date", this.blocked_date)
				}
        if(!this.dialog_all_branch) {
					post_data.append("branch", this.dialog_branch)
				}
				post_data.append("allow_online_booking", this.dialog_online_booking ? 1 : 0)
				post_data.append("block_for_all_staff", this.dialog_all_staff ? 1 : 0)
				post_data.append("block_for_all_day", this.dialog_all_date ? 1 : 0)
				post_data.append("description", this.dialog_description)
        if(this.update_block_time_id != null) {
          post_data.append("block_id", this.update_block_time_id)
        }
				CommonApi.post_data('innosoft_salon.api.block_appointment_time', post_data)
				.then(res => {
					if(res) {
						this.block_time_dialog = false;
            this.update_block_time_id = null;

						this.show_snackbar = true;
						this.snackbar_text = 'Blocked time created';
						this.snackbar_color = 'success';
						this.snackbar_icon = 'mdi-check-circle-outline';

            this.fetchEvents();
					}
				});
			}
		},
    delete_blocked_time() {
      if(this.update_block_time_id != null) {
        const post_data = new FormData();
				post_data.append("block_id", this.update_block_time_id)

        CommonApi.post_data('innosoft_salon.api.delete_block_time', post_data)
				.then(res => {
					if(res) {
						this.block_time_dialog = false;
            this.update_block_time_id = null;

						this.show_snackbar = true;
						this.snackbar_text = 'Blocked time deleted';
						this.snackbar_color = 'success';
						this.snackbar_icon = 'mdi-check-circle-outline';

            this.fetchEvents();
					}
				});
      }
    },
    save (date) {
      this.$refs.menu.save(date)
    },
    getValueSelected: function (value, roleSelect) {
      if (roleSelect === "categoryOfCalendar") {
        this.selectedCategoryOfCalendar = value;
      } else {
        this.selected = value;
      }
    },
    formatDate: function (date) {
      const newDate = new Date(date.days[0].date);
      let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
        newDate
      );
      let mm = new Intl.DateTimeFormat("en", { month: "long" }).format(newDate);
      let dd = new Intl.DateTimeFormat("en", {
        day: "2-digit",
      }).format(newDate);
      let tt = new Intl.DateTimeFormat("en", {
        weekday: "long",
      }).format(newDate);

      return `${tt} ${dd} ${mm}, ${ye}`;
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = new Date().toISOString().substr(0, 10);
      this.top_filter_date = moment(this.focus).format('dddd, D MMMM Y');
    },
    prev() {
      this.$refs.calendar.prev();
      this.top_filter_date = moment(this.focus).format('dddd, D MMMM Y');
    },
    next() {
      this.$refs.calendar.next();
      this.top_filter_date = moment(this.focus).format('dddd, D MMMM Y');
    },
    changeBranch() {
      this.start = 0
      this.pagination = 0
      localStorage.setItem('branch', this.selected_branch);
      this.allCategories = [
        { "id": "all", "employee_name": "All Employees" }
      ]
      this.fetchEvents();
    },
    async fetchEvents(){
      console.log(this.start)
      CommonApi.get_data('innosoft_salon.api.get_branch')
      .then(res => {
        if (res.status_code == 200) {
          this.allBranches.push(...res.data);
          if (!localStorage.getItem('branch')) {
            localStorage.setItem('branch', this.allBranches[0].id);
            this.selected_branch = this.allBranches[0].id;
          } else {
            this.selected_branch = localStorage.getItem("branch");
          }

          let branch_param = { branch: localStorage.getItem("branch"),start:this.start ,limit:50};
          branch_param = new URLSearchParams(branch_param);
          CommonApi.get_data('innosoft_salon.api.get_staff', branch_param)
          .then(res => {
            if (res.status_code == 200 && res.data.length >= 1) {
              this.staffs = res.data;
              this.dialog_staff = this.staffs[0].id;
              this.categories = res.data;
              this.currentCategories = res.data;
              this.start = res.data.length
              // this.pagination += 1
              this.allCategories.push(...res.data);

              let param = { date: this.focus, branch: localStorage.getItem("branch") };
              const params = new URLSearchParams(param);
              CommonApi.get_data('innosoft_salon.api.get_appontment_list', params)
              .then(res => {
                if (res.status_code == 200) {
                  this.all_appointment = res.data.all_appointment;
                  this.staff_wise_event = res.data.staff_wise;
                  
                  this.events = [];

                  this.get_blocked_time_events();
                  
                }
              })

            }
          })
        }
      })
    },
    get_blocked_time_events() {
      let param = { date: this.focus, branch:localStorage.getItem("branch") };
      const params = new URLSearchParams(param);
      CommonApi.get_data('innosoft_salon.api.get_block_appintment', params)
      .then(res => {
        if (res.status_code == 200) {
          this.all_blocked_time = res.data.all_block_time;
          this.staff_wise_blocked_time = res.data.staff_wise_details;

          if(this.defaultCategory == 'all' && this.selectedCalendarType == 'category') {
            this.push_to_events(this.all_appointment);
            this.push_blocked_events(this.all_blocked_time);
          } else {
            let staff_wise_selected = this.staff_wise_event[this.defaultCategory];
            this.push_to_events(staff_wise_selected);

            let blocked_staff_wise_selected = this.staff_wise_blocked_time[this.defaultCategory];
            this.push_blocked_events(blocked_staff_wise_selected);
          }
        }
      })
    },
    push_blocked_events(events) {
      // var allDay = this.rnd(0, 3) === 0;
      let events_data = {};
      
      if(events) {
        events.forEach((ap) => {                
          let start = new Date(ap.start).getTime();
          let end = new Date(ap.end).getTime();

          events_data = {
            start: start,
            end: end,
            color: '#a4adba',
            timed: true,
            category: ap.staff_name,
            description: ap.description,
            type : 2,
            staff_id : ap.staff_id,
            block_id : ap.block_id,
            allow_online_booking : ap.allow_online_booking,
            block_for_all_day : ap.block_for_all_day,
            block_for_all_staff : ap.block_for_all_staff
          };

          this.events.push(events_data);
        });  
      }    
    },
    push_to_events(appointments) {
      // var allDay = this.rnd(0, 3) === 0;
      const events = [];

      if(appointments) {
        appointments.forEach((ap) => {                
          let start = new Date(ap.start).getTime();
          let end = new Date(ap.end).getTime();

          events.push({
            start: start,
            end: end,
            name: ap.customer,
            color: (ap.sales_invoice ? '#dee3e7' : this.colors[this.rnd(0, this.colors.length - 1)]),
            timed: true,
            category: ap.staff_name,
            category_id : ap.staff,
            service: ap.service,
            appointment_id : ap.a_id,
            event_status : ap.status,
            rate : ap.rate,
            duration : ap.duration,
            s_id : ap.s_id,
            type : 1,
            paid : ap.paid,
            sales_invoice : ap.sales_invoice,
            customer_group : ap.customer_group
          });
        });

        this.events = events;
      }
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    changeCalendarType(value) {
      this.selectedCalendarType = value;
      // if selected multiple days, then select all employees
      if (value == "category") {
        this.defaultCategory = "all";
        this.currentCategories = this.categories;
      } else {
        if(this.defaultCategory == 'all') {
          this.defaultCategory = this.allCategories[1].id;
          this.currentCategories = [ this.allCategories[1] ];
        }
      }
    },
    changeCategory() {
      if (this.defaultCategory == "all") {
        this.currentCategories = this.categories;
        this.selectedCalendarType = "category";

        this.events = [];
        this.push_to_events(this.all_appointment);
        this.push_blocked_events(this.all_blocked_time);
      } else {        
        let cat_detail = this.categories.find(o => o.id === this.defaultCategory);
        this.currentCategories = [ cat_detail ];

        this.events = [];
        let staff_wise_selected = this.staff_wise_event[this.defaultCategory];
        this.push_to_events(staff_wise_selected);

        let blocked_staff_wise_selected = this.staff_wise_blocked_time[this.defaultCategory];
        this.push_blocked_events(blocked_staff_wise_selected);
      }

      // this.start = 1
    },
    clickInterval() {
      // console.log(value);
      console.log("value");
    },
    clickTime() {
      // console.log(value);
      console.log("value");
    },
    // show only interval label with complete hour
    showIntervalLabel(value) {
      if (value.minute == 0) {
        return true;
      }
    },
    intervalFormat(value) {
      return moment(value.time, 'hh A').format('h:mm a');
    },
    // callback funtion when click on interval time to create new event
    createNewEvent(date, time, employee) {
      if(this.select_blocked_time) {
        this.select_blocked_time = false;
        this.block_time_dialog = true;
        this.dialog_description = '';
        this.dialog_online_booking = false;
        this.dialog_all_date = false;
        this.dialog_all_staff = false;
        this.dialog_all_branch = false;

        this.dialog_branch = localStorage.getItem("branch");

        this.blocked_dialog_title = 'New Blocked Time';
        if(employee) {
          this.dialog_staff = employee.id;
        }
        this.dialog_start_time = moment(time, ["HH:mm"]).format('H:mm');
        this.dialog_end_time = moment(time, ["HH:mm"]).add(15, 'minutes').format('H:mm');
        this.blocked_date = date;
      } else if(this.change_app_mode) {
        var start_time = moment(time, ["HH:mm"]).format('HH:mm:ss')
        const post_data = new FormData();
        post_data.append("appointment_id", this.$route.query.app)
        post_data.append("from_time", start_time)
        post_data.append("staff", employee.id)
        post_data.append("date", date)
        CommonApi.post_data('innosoft_salon.api.reschedule_appointment', post_data)
        .then(res => {
          if(res) {
            this.show_snackbar = true;
            this.snackbar_text = 'Appointment rescheduled';
            this.snackbar_color = 'success';
            this.snackbar_icon = 'mdi-check-circle-outline';

            this.change_app_mode = false;
            this.$router.push('/calendar')
            this.fetchEvents();
          }
        });
      } else {
        if(this.selected_branch == 'All Branch') {
          this.show_snackbar = true;
          this.snackbar_text = 'Please select the branch.';
          this.snackbar_color = 'error';
          this.snackbar_icon = 'mdi-alert-outline';
        } else {
          /* Convert HH:mm to seconds */
          var a = time.split(':'); 
          var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60; 
          if (this.selectedCalendarType == "category") {
            this.$router.push('/appointment?startDate='+date+'&startTime='+seconds+'&employeeId='+employee.id);
          }else{
            this.$router.push('/appointment?startDate='+date+'&startTime='+seconds);
          }
        }
        
      }
    },
    startDrag({ event, timed }) {
      if (event && timed && event.type !== 2) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    startTime(tms) {
      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        this.dragTime = mouse - start;
      } else {
        // this.createStart = this.roundTime(mouse);
        // this.createEvent = {
        //   name: `Event #${this.events.length}`,
        //   color: this.rndElement(this.colors),
        //   start: this.createStart,
        //   end: this.createStart,
        //   timed: true,
        // };

        // this.events.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;

      this.extended_event = event;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;
        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
        this.extended_event = this.dragEvent
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      if(this.extended_event) {
        this.extendUpdateEvent(this.extended_event);
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;       
    },
    extendUpdateEvent(event) {
      var event_date = moment.unix(event.start/1000).format('YYYY-MM-DD')

      var start_time = moment.unix(event.start/1000).format('HH:mm:ss')
      var end_time = moment.unix(event.end/1000).format('HH:mm:ss')

      const post_data = new FormData();
      post_data.append("id", event.s_id)
      post_data.append("from_time", start_time)
      post_data.append("to_time", end_time)
      post_data.append("date", event_date)
      CommonApi.post_data('innosoft_salon.api.update_appointment', post_data)
      .then(res => {
        if(res) {
          this.show_snackbar = true;
          this.snackbar_text = 'Appointment rescheduled';
          this.snackbar_color = 'success';
          this.snackbar_icon = 'mdi-check-circle-outline';

          this.extended_event = null;
        }
      });
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }

      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
    ViewAppoitment({event}) {
      if(event.type == 2) {
        if(this.extended_event == null) {
          this.block_time_dialog = true;
          this.blocked_dialog_title = 'Edit Blocked Time';
          this.update_block_time_id = event.block_id;

          this.dialog_all_staff = event.block_for_all_staff;
          this.dialog_all_date = event.block_for_all_day;
          this.dialog_online_booking = event.allow_online_booking;

          this.blocked_date = moment.unix(event.start/1000).format('YYYY-MM-DD');
          this.dialog_start_time = moment.unix(event.start/1000).format('H:mm');
          this.dialog_end_time = moment.unix(event.end/1000).format('H:mm');

          this.dialog_staff = event.staff_id;
          this.dialog_branch = event.branch;
          this.dialog_description = event.description;
        }
      } else {
        this.clicks++;
        if (this.clicks === 1) {
          this.timer = setTimeout( () => {
            this.clicks = 0
          }, this.delay);
        } else {
            clearTimeout(this.timer);  
            this.$router.push('/view_appointment/'+event.appointment_id+'/'+event.category_id);
            this.clicks = 0;
        }
      }  
    },
    checkBlockTime(date, time, category){    
      if(this.blocked_data.some(data => (data.date === date && data.time === time && data.category == category))){
          //don't exists     
          return false;
      }
      // if(time == "09:00"){
      //   return false;
      // }
      var all = false;
      // condition for disable everything, check with any data variable
      if(all == true){
        return false;
      }
      return true;
    },
    getCategoryLabel(category){
      // get category label by category id from this funtion
      return category.categoryName;
    },
    openPopover({nativeEvent, event}) {
      this.popup_type = event.type;

      this.selectedEvent = event
      this.selectedElement = nativeEvent.target
      requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      nativeEvent.stopPropagation()
    },
    closePopover({nativeEvent}) {
      if (this.selectedOpen) {
        this.selectedOpen = false
        // requestAnimationFrame(() => requestAnimationFrame(() => open()))
      }
      nativeEvent.stopPropagation()
    },
    chooseBlockedTime() {
      this.select_blocked_time = true;
    },
    blocked_time_format(time) {
      let start_t = moment(time, ["HH:mm"]).format('h:mma');
      let end_t = moment(time, ["HH:mm"]).add(15, 'minutes').format('h:mma');
      
      return start_t +' - '+ end_t;
    }
  }  
};
</script>


<style lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}

.interval-slot-time {
  display: none;
  cursor: pointer;
  color: #424242;
}
.interval-slot-time.blocked {
  display: block;
  background-color: #eee;
  color: #ddd;
  cursor: default;
}
.v-calendar-daily__day-interval:hover .interval-slot-time {
  display: block;
  // background-color: #eee;
  background-color: #e5f1ff;
  color: #037aff;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  padding-left: 8px;
  text-align: left !important;
}
.v-calendar-daily__day-interval:hover .block-time-slot {
  background-color: #a4adba;
  color: #101928;
}
.v-calendar-daily__day-interval:hover .interval-slot-time.blocked {
  display: block;
  background-color: #eee;
  color: #ddd;
}
.v-calendar-daily .v-calendar-daily__day-interval {
  z-index: 7 !important;
  position: relative !important;
}
.v-calendar-events .v-event-timed {
  z-index: 7 !important;
}
.v-calendar .v-event-timed-container {
  margin-right: 0 !important;
}

// .v-calendar-events .v-event-timed .select_time_overlay:parent { 
//   z-index: 0 !important; 
// }

.v-current-time {
  height: 1.5px;
  background-color: #e45a74;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;
  // z-index: 99999;

  // &.first::before {
  //   content: "";
  //   position: absolute;
  //   background-color: #e45a74;
  //   width: 12px;
  //   height: 12px;
  //   border-radius: 50%;
  //   margin-top: -5px;
  //   margin-left: -6.5px;
  // }
  // &.first::before {
  //   background: #fff;
  //   border-radius: 10px;
  //   position: absolute;
  //   height: 20px;
  //   margin-top: -10px;
  //   font-size: 11px;
  //   line-height: 11px;
  //   font-weight: 500;
  //   color: #e45a74;
  //   border: 2px solid #e45a74;
  //   width: 100%;
  // }
}
// .v-calendar-daily__day-container > 
// .v-current-time::before {
  // border: 1px solid red;
  // &.first::before {
    // background: #fff;
    // border-radius: 10px;
    // position: absolute;
    // height: 20px;
    // margin-top: -10px;
    // font-size: 11px;
    // line-height: 11px;
    // font-weight: 500;
    // color: #e45a74;
    // border: 2px solid #e45a74;
    // width: 100%;
  // }
// }
.v-calendar-daily__interval-text {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #212121 !important;
  line-height: 12px;
}
.v-calendar-daily__interval:first-child {
  padding-top: 10px;
}
// .v-calendar-daily_head-weekday, .v-calendar-daily_head-day-label {
//   display: none;
// }
.theme--light.v-calendar-events .v-event-timed {
  border: none !important;
}
.v-event-drag-bottom::after {
  height: 6px;
  border-top: 2px solid #00000080;
  border-bottom: 2px solid #00000080;
  width: 14px;
  opacity: revert;
}
div .theme--light.v-calendar-events .v-event-timed {
  // border: 1px solid #a5dff8 !important;
  border: none !important;
}

div .theme--light.v-calendar-events .v-event-timed:hover {
  box-shadow:0 10px 10px 0 rgba(164,173,186,.25);
}
.date_button .theme--light.v-btn--active::before {
  opacity: 0.13 !important;
}
</style>